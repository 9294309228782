import { adminClient } from "@api/admin/AdminClient";
import EditValueDialogComponent from "@components/admin/EditValueDialogComponent";
import ToastContainer, { createErrorToast, createWarningToast } from "@components/ToastContainer";
import { Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, NoSsr, TextField } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import { ApiCallError, getJwtCookiePayload, hasAdministratorAccessThroughJwt, hasExactlyDashboardBasicAccessThroughJwt, hasExactlyDashboardFullAccessThroughJwt } from "@utils/swaggerClientUtils";
import { useEffect, useMemo, useState } from "react";
import sheriff from "../../pages/images/layout/sheriffen.jpg";

export default function LoginPageComponent() {
	const [refresh, setRefresh] = useState(false);
	const [isVerificationDialogOpen, setIsVerificationDialogOpen] = useState<boolean>(false);
	const [currentVerificationCode, setCurrentVerificationCode] = useState<string>();

	const [email, setEmail] = useState<string>(() => {
		if (typeof window === "undefined")
			return "";

		return window.localStorage.getItem('email') || "";
	});

	const [password, setPassword] = useState<string>("");
	const emailError = useMemo(() => !email && !!password && "No email specified.", [email, password]);
	const passwordError = useMemo(() => !password && !!email && "No password specified.", [password, email]);
	const [isTheSheriffShowing, setTheSheriffShowing] = useState<boolean>(false);

	useEffect(() => {
		var jwtPayload = getJwtCookiePayload();
		if (hasExactlyDashboardFullAccessThroughJwt(jwtPayload) || hasExactlyDashboardBasicAccessThroughJwt(jwtPayload)) {
			window.location.href = "/admin/dashboards";
		}

		if (hasAdministratorAccessThroughJwt(jwtPayload)) {
			window.location.href = `/admin/users/details/?id=${jwtPayload?.sub}`;
		}
	}, [refresh]);


	const onSignInClicked = async (e: any) => {
		e.preventDefault();
		try {

			await adminClient().adminAuthenticationSendVerificationCodePost({
				body: {
					email,
					password
				}
			});

			setIsVerificationDialogOpen(true);
		} catch (error) {
			createErrorToast("An error occured during login.");
			throw error;
		}
	};

	const onVerifyClicked = async (e: any) => {
		e.preventDefault();

		try {
			await adminClient().adminAuthenticationLoginPost({
				body: {
					email,
					password,
					verificationCode: currentVerificationCode
				}
			});
			setIsVerificationDialogOpen(false);
			setRefresh((oldRefresh) => !oldRefresh);

		} catch (error) {
			if (error instanceof ApiCallError && error.statusCode === 401) {
				createWarningToast("An error occured during login.");
				return;
			}
			createErrorToast("An error occured during login.");
			throw error;
		}
	}

	return <>
		<NoSsr>
			<Container>
				<Card style={{
					position: "relative",
					zIndex: 10,
					maxWidth: "400px",
					margin: "5vh auto 20px auto"
				}}>
					<CardContent>
						<Box component="form" onSubmit={(e) => onSignInClicked(e)}>
							<TextField
								value={email}
								onChange={ev => {
									setEmail(ev.target.value);
									typeof window !== "undefined" && window.localStorage.setItem("email", ev.target.value);
								}}
								error={!!emailError}
								helperText={emailError}
								fullWidth
								label="Email"
								type="email"
								InputLabelProps={{ shrink: typeof window !== "undefined" && email !== "" ? true : false }}
								style={{ marginBottom: "20px" }}
							/>

							<TextField
								value={password}
								onChange={ev => setPassword(ev.target.value)}
								error={!!passwordError}
								helperText={passwordError}
								fullWidth
								label="Password"
								type="password"
								style={{ marginBottom: "32px" }}
							/>

							<Box display="flex" alignItems="center">
								<EnvironmentPickerButton />
								<Button
									type="submit"
									variant="contained"
									color="primary"
									style={{ flex: "1 1 auto" }}
								>
									Sign in
								</Button>
							</Box>
						</Box>
					</CardContent>
				</Card>
				<ToastContainer />
				<Box
					style={{
						position: "relative",
						width: "100%",
						maxWidth: "480px",
						margin: "auto",
						backgroundColor: "#fff"
					}}>
					<Box style={{
						position: "absolute",
						width: "25%",
						height: "15%",
						zIndex: 6,
						top: "64%",
						left: "23%"
					}}
						onClick={() => setTheSheriffShowing((prev) => !prev)} />
					<img
						width={175}
						height={130}
						style={{
							position: "absolute",
							top: isTheSheriffShowing ? "2%" : "19%",
							right: isTheSheriffShowing ? "5%" : "22%",
							width: isTheSheriffShowing ? "37%" : "0px",
							height: isTheSheriffShowing ? "27%" : "0px",
							zIndex: 1,
							transform: "rotate(35deg)",
							transition: isTheSheriffShowing ? "top 300ms ease-in-out, right 300ms ease-in-out" : "top 300ms ease-in-out, right 300ms ease-in-out, height 0ms linear 350ms, width 0ms linear 350ms"
						}}
						src={sheriff}
					/>
					<img
						style={{
							position: "relative",
							zIndex: 5,
							width: "100%",
							maxWidth: "480px",
							display: "block",
							marginLeft: "auto",
							marginRight: "auto"
						}}
						src="https://media3.giphy.com/media/gFPcGTz3M9oc7YaNDA/giphy.gif"
					/>
				</Box>
			</Container>


			<Dialog
				onClose={() => setIsVerificationDialogOpen(false)}
				open={isVerificationDialogOpen}>
				<DialogTitle style={{ paddingBottom: 0 }}>Enter verification code</DialogTitle>
				<Box component="form" onSubmit={async (e) => await onVerifyClicked(e)}>
					<DialogContent>
						<TextField
							value={currentVerificationCode}
							onChange={(e) => setCurrentVerificationCode(e.target.value)}
							variant="outlined"
							autoFocus
						/>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							fullWidth
						>
							Verify
						</Button>
					</DialogActions>
				</Box>
			</Dialog>
		</NoSsr>
	</>
}

function EnvironmentPickerButton() {
	const [isEnvironmentSelectorOpen, setIsEnvironmentSelectorOpen] = useState(false);
	const [isCustomUrlDialogOpen, setIsCustomUrlDialogOpen] = useState(false);

	const environmentsJson =
		typeof localStorage !== "undefined" &&
		localStorage.getItem("previous-base-urls-array");

	const environmentUrls: string[] = environmentsJson ? JSON.parse(environmentsJson) : [];
	environmentUrls.push("https://app.reshopper.com/");
	environmentUrls.push("http://localhost:5000/");

	const onEnvironmentSelected = (environmentUrl: string) => {
		let lowerUrl = environmentUrl.toLowerCase();
		if (!lowerUrl.endsWith("/"))
			lowerUrl += "/";

		if (!lowerUrl.startsWith("https://") && !lowerUrl.startsWith("http://"))
			lowerUrl = "https://" + lowerUrl;

		if (environmentUrls.indexOf(lowerUrl) === -1)
			environmentUrls.push(lowerUrl);

		sessionStorage.setItem("api-base-url", lowerUrl);
		localStorage.setItem("previous-base-urls-array", JSON.stringify(environmentUrls));

		setIsEnvironmentSelectorOpen(false);
		setIsCustomUrlDialogOpen(false);
	}

	return <>
		<IconButton
			onClick={() => setIsEnvironmentSelectorOpen(true)}
			style={{
				marginRight: 8
			}}
		>
			<LanguageIcon />
		</IconButton>
		<Dialog onClose={() => setIsEnvironmentSelectorOpen(false)} aria-labelledby="simple-dialog-title" open={isEnvironmentSelectorOpen}>
			<DialogTitle>Set API client base URL</DialogTitle>
			<List>
				{environmentUrls.map(environmentUrl => (
					<ListItem button onClick={() => onEnvironmentSelected(environmentUrl)} key={"url-" + environmentUrl}>
						<ListItemText primary={environmentUrl} />
					</ListItem>
				))}
				<ListItem button onClick={() => setIsCustomUrlDialogOpen(true)}>
					<ListItemText primary="Custom environment" />
				</ListItem>
			</List>
		</Dialog>
		<EditValueDialogComponent
			isDialogOpen={isCustomUrlDialogOpen}
			onClose={() => setIsCustomUrlDialogOpen(false)}
			onSubmit={url => onEnvironmentSelected(url)}
			title={"Enter the API base URL"}
			description={"For instance \"https://app.reshopper.com/\"."}
		/>
	</>
}